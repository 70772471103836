import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрий для себе пейнтбольний клуб Target Zone
			</title>
			<meta name={"description"} content={"Дослідіть свою пристрасть до пейнтболу, як ніколи раніше!"} />
			<meta property={"og:title"} content={"Про нас | Відкрий для себе пейнтбольний клуб Target Zone"} />
			<meta property={"og:description"} content={"Дослідіть свою пристрасть до пейнтболу, як ніколи раніше!"} />
			<meta property={"og:image"} content={"https://snugafit.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://snugafit.com/img/34326346.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://snugafit.com/img/34326346.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://snugafit.com/img/34326346.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://snugafit.com/img/34326346.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://snugafit.com/img/34326346.png"} />
			<meta name={"msapplication-TileImage"} content={"https://snugafit.com/img/34326346.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Від свого скромного початку як простого поля для місцевих ентузіастів, пейнтбольний клуб Target Zone перетворився на головне місце для гравців у пейнтбол, які шукають виклику та азарту. Наше бачення полягало в тому, щоб створити місце, де стратегія, командна робота та дії об'єднуються, щоб забезпечити захоплюючий досвід. З роками ми розширили нашу територію та оновили обладнання, зберігаючи при цьому основний дух веселої та змагальної гри.
				</Text>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Наші поля для гри
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					У Target Zone ми пропонуємо різноманітні тематичні пейнтбольні поля, кожне з яких спроектоване таким чином, щоб забезпечити унікальний тактичний виклик. Від густих лісових масивів для скритності та стратегії до відкритих майданчиків, що ідеально підходять для високоенергетичних сутичок, наші поля задовольняють усі стилі гри в пейнтбол. Такий різноманітний асортимент гарантує, що жодні два відвідування Target Zone не будуть однаковими.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://snugafit.com/img/6.jpg"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://snugafit.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 0px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Чому Target Zone вирізняється з-поміж інших
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					• Інноваційні ігрові формати: Випробуйте унікальні ігрові режими, розроблені для покращення ваших навичок та розваг.
					<br />
					<br />
					• Безпека понад усе: Комплексні протоколи безпеки та найсучасніше екіпірування для захисту всіх гравців.
					<br />
					<br />
					• Гра за будь-якої погоди: У будь-яку погоду наші поля готові до гри, гарантуючи, що ваші пейнтбольні плани ніколи не будуть порушені.
					<br />
					<br />
					• Сімейний відпочинок: Заходи та місця, призначені для сімей та молодших гравців, що робить пейнтбол доступним для всіх.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Приєднуйтесь до пригод у Target Zone
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Вирушайте у подорож, сповнену азарту та дружби, до пейнтбольного клубу Target Zone. Незалежно від того, чи хочете ви покращити свої тактичні навички або просто весело провести день, ми забезпечимо ідеальне тло для створення незабутніх спогадів. Приєднуйтесь до нас і створіть свою власну захоплюючу пейнтбольну сагу!
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://snugafit.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});